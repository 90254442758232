import React from 'react'

const TestimonialsPage = () => {

  return (
    <div>Oops, there's nothing on this page.</div>
  )
}

export default TestimonialsPage
